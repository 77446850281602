import { FaChartLine } from 'react-icons/fa6';
import { BsEyeglasses } from "react-icons/bs";
import { ApproachCard } from './ApproachCard';
import { TbSettingsAutomation } from "react-icons/tb"
import visibility from "../../pages/CloudVue/icons/visibility.png";
import automation from "../../pages/CloudVue/icons/automation.png";
import optimisation from "../../pages/CloudVue/icons/optimisation.png";

export function OurApproach() {
  const itemList = [{
    icon: <img className="navbarImg " src={visibility} style={{ width: 300 }} alt="logo" />,
    title: 'GIVING YOU DEEP VISIBILITY',
    text: 'Our cloud-native approach enables flexible cost allocation, fosters cost awareness, and compares costs across various cloud providers.'
  },
  {
    icon: <img className="navbarImg " src={optimisation} style={{ width: 300 }} alt="logo" />,
    title: 'ENABLING OPTIMISATION',
    text: 'CloudVue curates metrics for cost optimisation, auto-maps labels to cloud resources, and enables organisations to control costs through adjustable thresholds and alerts.'
  },
  {
    icon: <img className="navbarImg " src={automation} style={{ width: 320 }} alt="logo" />,
    title: 'OFFERING AUTOMATION',
    text: 'Our solution integrates with GitOps via Kubernetes manifests, enabling automatic control of applications based on cost recommendations and optimisations.'
  },
  ]
  return (
    <div id="OurApproach" className='banner' style={{ height: '100%' }}>
      <h1>Our Approach</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {itemList.map((el) => {
          return <ApproachCard icon={el.icon} title={el.title} text={el.text} />
        })}
      </div>
      <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ width: '40%' }}>
          <h1>FinOps</h1>
          <span>
            These are the three pillars of FinOps. If you are interested in learning more about FinOps we recommend that you look into the courses provided by them: <a href='https://learn.finops.org/introduction-to-finops' target="_blank">https://learn.finops.org/introduction-to-finops</a>
          </span>
        </div>
      </div>
    </div>
  )
}
