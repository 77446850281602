import './index.css'
import emailjs from 'emailjs-com';
export function ContactForm() {
    function sendEmail(e: any) {
        console.log('test')
        console.log(e)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_ixcm2tk', 'template_sgq4hab', e.target, 'O0Cpq_RtsmAGIgLVY')
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="eMailForm">
            <form className="contact-form" onSubmit={sendEmail}>
                <input type="hidden" name="contact_number" />
                <div className='formElement' style={{ display: 'flex' }}>
                    <div className='BannerSplitter ' style={{ marginRight: '10px' }}>
                        <div>Firstname *</div>
                        <input type="text" id="fname" name="fname" className="fname  inputField " required />
                    </div>
                    <div className=' BannerSplitter ' style={{ marginLeft: '10px' }}>
                        <div>Name</div>
                        <input type="text" id='name' name="name" className="name inputField" required />
                    </div>
                </div>

                <div className='formElement'>
                    <div>
                        <div>Company *</div>
                        <input type="text" id="company" name="company" className="company formElement inputField" required />
                    </div>
                </div>
                <div className='formElement'>
                    <div>
                        <div> E-mail *</div>
                        <input type="email" id="e-mail" name="from_email" className="e-mail formElement inputField" required />
                    </div>
                </div>
                <div className='formElement'>
                    <div>
                        <div>Leave us a message *</div>
                        <textarea style={{ resize: 'none' }} id="message" rows={10} name="html_message" className="message formElement textAreaField" required />
                    </div>
                </div>
                <button className="DemoButton" type='submit' value="Send">
                    SEND
                </button>
            </form>
        </div>
    )
}