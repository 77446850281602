import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import './App.css';
import { CloudVueIndex } from './pages/CloudVue'

function App() {
  const theme = createTheme({
    /** Put your mantine theme override here */
  });
  return (
    <div className="App">
      <MantineProvider theme={theme}>
        <CloudVueIndex></CloudVueIndex>
      </MantineProvider>
    </div>
  );
}

export default App;
