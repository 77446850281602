import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import './index.css'

interface ApproachCardProps {
  icon: JSX.Element,
  title: string,
  text: string
}
export function ApproachCard({ icon, title, text }: ApproachCardProps) {
  return (
    <div style={{padding: '10px', height: '520px'}}>
      <Card h={500} w={400} shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <div style={{display:'flex', width:'100%', paddingLeft:'10px'}}>
          {icon}
          </div>
        </Card.Section>

        <Group justify="center" mt="md" mb="xs">
          <Text fw={500}>{title}</Text>
        </Group>

        <Text size="sm" c="dimmed">
          {text}
        </Text>
      </Card>
    </div>
  )
}