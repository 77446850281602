type video = {
  id:string
  title: string
  link: string
  linkToInfo?: string
  comment: string
}
export const videos: video[] = [

  {
    id: 'vid1',
    title: 'Dashboard creation and editing',
    link: 'https://www.youtube.com/embed/r4whwJULC4w',
    linkToInfo:'https://www.finops.org/framework/personas/',
    comment: 'CloudVue provides a configurable dashboard. This means that you as the user gets to decide what you want to see. There are many different widgets that you can choose from. Several predefined layout are profided, these are attached to FinOps personas: '
  },{
    id: 'vid2',
    title: 'Predefined widgets',
    linkToInfo: 'https://www.finops.org/finops-use-cases/',
    link: 'https://www.youtube.com/embed/_Mmiirio8-Q',
    comment: 'There are many widgets predefined for you as a user, this to help you focus on the more important task of understanding your data. There are several widgets created for you by the developers themselves, and there are some based on the FinOps use cases: '
  },
  {
    id: 'vid3',
    title: 'Optimization widget',
    link: 'https://www.youtube.com/embed/La29a6Luzmg',
    comment: 'The Optimization widget allows you to see recommendations across your cloud providers. It provides data that shows how much money you could save if you did the recommended actions. You can check off what you have already accomplished. There is a second view that visualises the potential savings.'
  },
  {
    id: 'vid4',
    title: 'Group by product',
    link: 'https://www.youtube.com/embed/ztqTHDDN3sk',
    comment: 'By using this widget you will gain the ability to look at your data by grouping them on a specifc focus column. There are two sides to this widget, one that will show the grouped data for one month and displays it in a piechart. Another will show this data in a stacked barchart over a selected period of time.'
  },

  {
    id: 'vid5',
    title: 'Aggregate by cost widget',
    link: 'https://www.youtube.com/embed/5MrRXJLDxho',
    comment: 'This widget fetches your data, processed by the system to be more informative. This data will then be represented inside of a table, which you can order/hide/filter the columns of. You can also group based on a column. when clicking a row it will fetch the historical data of that resource data and display that in a bar chart.'
  },
]