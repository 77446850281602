import imgBart from "../../pages/CloudVue/icons/team/bart.jpg"
import imgAndrew from "../../pages/CloudVue/icons/team/andrew.jpg"
import imgDejan from "../../pages/CloudVue/icons/team/dejan.jpg"
import imgWietse from "../../pages/CloudVue/icons/team/wietse.jpg"
import imgJeff from "../../pages/CloudVue/icons/team/jeff.jpg"
import imgTom from "../../pages/CloudVue/icons/team/tom.jpg"
import imgBert from "../../pages/CloudVue/icons/team/bert.jpg"
export type employee = {
  name: string, lname: string, position: string, info: string, image: JSX.Element, linkedInLink?: string
}
export const ourTeam: employee[] = [
  { name: 'Andrew', lname: 'Pye', position: 'Back-end', info: 'Nearly 20 years of experience as an Automation Developer.', image: <img height={250} src={imgAndrew} alt="Andrew" />, linkedInLink: 'https://www.linkedin.com/in/andrew-pye-107528119/' },
  { name: 'Dejan', lname: 'Ilic', position: 'Data Scientist', info: 'Data Science, PhD VUB, Former math teacher and developer at Skryv.', image: <img height={250} src={imgDejan} alt="Dejan" />, linkedInLink: 'https://www.linkedin.com/in/dejan-ilic-a22125190/' },
  { name: 'Wietse', lname: 'Van den Hove', position: 'Full-Stack developer', info: 'Master Applied Informatics VUB.', image: <img height={250} src={imgWietse} alt="Wietse" />, linkedInLink: 'https://www.linkedin.com/in/wietse-van-den-hove-8a8b412b3' },
]

export const ourExecutives: employee[] = [
  { name: 'Jeff', lname: 'de Kleijn', position: 'CEO', info: 'Jeff, the former head of HP’s incubation businesses in China and Asia-Pacific.', image: <img height={250} src={imgJeff} alt="Jeff" />, linkedInLink: 'https://www.linkedin.com/in/jeffdekleijn/' },
  { name: 'Tom', lname: 'Arentsen', position: 'CTO', info: 'More than 25 years of experience in the IT industry, spanning all virtualization and cloud computing areas.', image: <img height={250} src={imgTom} alt="Tom" />, linkedInLink: 'https://www.linkedin.com/in/tomarentsen/' },
  { name: 'Bart', lname: 'Clijsner', position: 'Lead Developer', info: 'Experienced Software Developer and PXL-IT Lecturer', image: <img height={250} src={imgBart} alt="Bart" />, linkedInLink:'https://www.linkedin.com/in/bart-clijsner-40365425/' },
]
