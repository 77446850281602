interface HomePageBannerProps {
  title: string | JSX.Element
  content: JSX.Element
  contrast: 'light' | 'dark'

}
export function HomePageBanner({ title, content, contrast }: HomePageBannerProps) {
  return (
    <div style={{ width: '100%', height: 'fit-content', padding: '50px', borderTop: '1px solid #d1d5db', background: contrast === 'light' ? 'white' : '#353535', color: contrast === 'light' ? 'inherit' : 'white' }}>
      <h1>{title}</h1>
      <div style={{ width: '100%', height: 'fit-content', minHeight:'100px' }}>{content}</div>
    </div>
  )
}