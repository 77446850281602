import imageToAdd from "./icons/CloudVue-logo-landscape-v1.svg";
import './index.css'
import { AboutUs } from '../../Components/AboutUs/AboutUs';
import { Homepage } from '../../Components/Homepage';
import { Contact } from '../../Components/Form/contact';
import { useState } from 'react';
import { Tutorial } from '../../Components/Tutorial';
import { OurApproach } from '../../Components/OurApproach/index';

export function CloudVueIndex() {
    const [chosenView, setChosenView] = useState<string>('Homepage')


    return (
        <div>
            <div style={{ display: 'flex' }}>
                {/* Navbar */}
                <div className="topnav toMiddle">
                    <img className="navbarImg" src={imageToAdd} style={{ width: 270, paddingRight: '15%' }} alt="logo" />
                    <a className="link" onClick={() => {
                        setChosenView('Homepage')
                    }} href="#Homepage">Homepage</a>

                    <a className="link" onClick={() => {
                        setChosenView('OurApproach')
                    }} href="#OurApproach">Our Approach</a>

                    <a className="link" onClick={() => {
                        setChosenView('WhyChooseCloudVue')
                    }} href="#WhyChooseCloudVue">About Us</a>

                    <a className="link" onClick={() => {
                        setChosenView('Contact')
                    }} href="#Contact">Contact</a>
                </div>
            </div>

            <div className='HomepageHeaderColor'>
                {/* Banner 1 */}
                {chosenView === 'Homepage' && (
                    <Homepage contactCallback={() => { setChosenView('Contact') }} />
                )}
                {/* Banner 3 */}
                {chosenView === 'OurApproach' && (
                    <OurApproach />
                )}

                {/* Banner 3 */}
                {/* {chosenView === 'Tutorial' && (
                    <Tutorial />
                )} */}

                {/* Banner 4 */}
                {chosenView === 'WhyChooseCloudVue' && (
                    <div id="WhyChooseCloudVue" style={{ width: '100%', height:'100%', display: 'block', paddingTop: '200px', alignItems: 'center' }}>
                        <AboutUs />
                    </div>
                )}

                {/* Banner 5 */}
                {chosenView === 'Contact' && (
                    <Contact />
                )}
                {/* Banner 6 */}
                <div className="toBottom toMiddle">
                    © 2024 - Website developed by DOME BV
                </div>
            </div>
        </div>
    )
}