import multiCloudImg from "../../pages/CloudVue/icons/multiCloud.svg";
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import './index.css'
import imageToAdd from "../../pages/CloudVue/icons/CloudVue-logo-landscape-v1.svg";

import { HomePageBanner } from './HomePageBanner';
import { videos } from './homePageVideos';
import { Card, Group, Text } from '@mantine/core';
import 'video.js';

interface HomepageProps {
  contactCallback: () => void
}
export function Homepage({ contactCallback }: HomepageProps) {


  return (
    // <ArcherContainer strokeColor="#668957" >
    <div id="Homepage" style={{ display: 'block' }}>
      <div className="banner">
        <div className="Header">
          <h1>A New Era in Cloud Cost Management and Optimisation</h1>
          <h2>Bridging the Gap Between Finance and Engineering</h2>
          {/*
            <ArcherElement
              id="root"
              relations={[
                {
                  targetId: 'element1',
                  targetAnchor: 'top',
                  sourceAnchor: 'bottom',
                  style: { endMarker: false, strokeWidth: 10 }
                },
              ]}

            > */}
          <button id="DemoButton" className="DemoButton" onClick={contactCallback}>
            BOOK A DEMO!
          </button>
          {/* </ArcherElement> */}
        </div>
      </div>
      <div style={{ paddingTop: '200px'}}>
        <HomePageBanner
          contrast='dark'
          title='Why choose CloudVue?'
          content={
            <span>
              CloudVue stands out in a competitive market with key differentiators that set the solution apart from other cost management platforms. CloudVue provides a cloud-native approach to cost management that integrates seamlessly with existing application and infrastructure implementations.
              Want a demo on how CloudVue can unlock the full potential of your cloud services, optimise your spending, and bridge the gap between finance and engineering.
            </span>
          }
        />
        <HomePageBanner
          contrast='light'
          title='Gain insight of the costs accumulated from all the cloud providers you use!'
          content={
            <div>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <h3 style={{ width: 1000 }}>Using services across different cloud providers is bound to make it tricky to know how much you are spending for something. Use CloudVue to make this task that much easier. Use its tools to discover parts of your data that you would not discover otherwise</h3>
              </div>
              <img src={multiCloudImg} style={{ width: 1000, height: 500 }} alt="logo" />
            </div>
          }
        />
        <HomePageBanner
          contrast='dark'
          title='Visualise your data in a variety of ways!'
          content={
            <div>
              <h3>At CloudVue helping you to discover and understand your data is our main goal. Listed here are some ways we attempt to accomplish this.</h3>
              <Carousel
                withIndicators
                style={{ width: '100%' }}
                slideSize={{ base: '70%', sm: '50%', md: '33.333333%' }}
                slideGap={{ base: 0, sm: 'md' }}
                loop
                align="center"
              >
                {
                  videos.map((element) => {
                    return (
                      <Carousel.Slide>
                        <Card h={700} w={700} shadow="sm" padding="lg" radius="md" withBorder>
                          <Card.Section>
                            <div style={{ height: 459, width: 699, borderRadius: '25px' }}>
                              <iframe
                                style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
                                id={element.id}
                                width="699"
                                height="459"
                                src={`${element.link}?showinfo=0&rel=0`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                frameBorder={0}
                                title="Embedded youtube"
                              ></iframe>
                            </div>
                          </Card.Section>

                          <Group justify="center" mt="md" mb="xs">
                            <Text fw={500}>{element.title}</Text>
                          </Group>

                          <Text size="sm" c="dimmed">
                            {element.comment}<a href={element.linkToInfo} target="_blank" rel="noreferrer">{element.linkToInfo}</a>
                          </Text>
                        </Card>
                      </Carousel.Slide>
                    )
                  })
                }
              </Carousel>
            </div>
          }
        />
        <HomePageBanner
          contrast='light'
          title="Make informed decisions!"
          content={
            <span>
              <img src={imageToAdd} style={{ width: 500 }} alt="logo" />
              {/* Now you can use your data productively, use it to make even better decisions! */}
            </span>
          }
        />
      </div>
    </div >
  )
}