import { FaLinkedinIn } from 'react-icons/fa6';
import { ContactForm } from '.';

export function Contact() {
  return (
    <div id="Contact" style={{ paddingBottom: '130px', paddingTop:'200px'}}>

      <div className="sideBySideBanner">
        <div className="BannerSplitter">
          <div className="toMiddle" style={{justifyContent:'center'}}>
            <div className="inBlock txtAlignLeft" style={{ width: '50%' }}>
              <h3>CONTACT US</h3>
              Need any help? The CloudVue team is available to help you. Contact us if you have questions or comments! Don't hesitate to get in touch with us.
              <a className="link" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { window.open('https://www.google.com/maps/dir//Miriam+Makebaplein+2,+9000+Gent/@51.048177,3.7282627,20z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c3714488ddccf3:0xb35511c99e7d9abb!2m2!1d3.7284836!2d51.0482024?entry=ttu', '_blank'); }}>
                <div>Wintercircus</div>
                <div>Miriam Makebaplein 2</div>
                <div>9000 Gent</div>
                <div>BELGIUM</div>
              </a>
              <FaLinkedinIn className="linkedIn" size={20} onClick={() => { window.open('https://www.linkedin.com/company/cloudvue-finops/mycompany/', '_blank') }} />
            </div>
          </div>
        </div>
        <div className="BannerSplitter">
          <div className="toMiddle">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}