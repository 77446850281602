import { useRef, useState } from 'react';
import { EmployeeCard } from './EmployeeCard';
import { WhyChooseUs } from './whyChooseUs';
import { FaArrowLeft, FaArrowRight, FaLinkedinIn } from 'react-icons/fa6';
import { ourExecutives, ourTeam } from './utils';
import { Carousel } from '@mantine/carousel';
import { Card, Group, Text } from '@mantine/core';

export function AboutUs() {
  return (
    <div style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: '70px' }}>
      <h1>Our executives</h1>
      <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: '10px' }}>
        {ourExecutives.map((member) => {
          return (
            <Card style={{ margin: '10px' }} h={400} w={250} shadow="sm" padding="lg" radius="md" withBorder>
              <Card.Section>
                {member.image}
              </Card.Section>

              <Text fw={650}>{member.position}</Text>

              <Group justify="center" mt="md" mb="xs">
                <Text fw={500}>{member.name} {member.lname}</Text>
                <FaLinkedinIn className="linkedIn" size={20} onClick={() => { window.open(member.linkedInLink, '_blank') }} />
              </Group>

              <Text size="sm" c="dimmed">
                {member.info}
              </Text>
            </Card>
          )
        })}
      </div>
      <h1>Our development team</h1>
      <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', paddingBottom: '70px' }}>
        {ourTeam.map((member) => {
          return (

            <Card style={{ margin: '10px' }} h={400} w={250} shadow="sm" padding="lg" radius="md" withBorder>
              <Card.Section>
                {member.image}
              </Card.Section>

              <Group justify="center" mt="md" mb="xs">
                <Text fw={500}>{member.name} {member.lname}</Text>
                <FaLinkedinIn className="linkedIn" size={20} onClick={() => { window.open(member.linkedInLink, '_blank') }} />
              </Group>

              <Group>
                <Text size="sm" c="dimmed">
                  {member.info}
                </Text>
              </Group>
            </Card>
          )
        })}
      </div>
    </div >
  )
}